<template>
    <div class="product-item" itemscope itemtype="http://schema.org/Thing">
        <div class="product__card product__Card--color d-flex flex-column align-items-center position-relative mt-5">
            <img itemprop="image" class="product__image mb-4 mb-lg-0" :src="require('@/assets/' + image)" alt="Compresor">
            <h5 itemprop="name" class="mt-5 mb-0 product__card-title--style product__card-text--color">
                {{ text }}
            </h5>
            <a class="product-card__button--position" href="#contact-form" @click="eventButton" v-smooth-scroll="smoothScroolConfig">
                <div class="product-card__button product-card__button--style d-flex align-items-center justify-content-center">
                    <p class="m-0 text-center">Más información</p>    
                </div> 
            </a> 
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductLast',
    props: {
        image: String,
        text: String,
        productID: String
    },
    data() {
        return {
            smoothScroolConfig: {   
                duration: 350, 
                offset: -85, 
                container: '', 
                updateHistory: true 
            }
        }
    }, 
    methods: {
        eventButton: function() {
            this.$store.dispatch('clickingCTA', this.productID);
             this.$gtag.event('Click', {
                'event_category': 'Product',
                'event_label': 'Más productos',
            })
        }
    }
}
</script>

<style scoped>
    /* Text */
    .product__card-title--style{
        font-size: 18px;
        font-weight: 500;
        text-align: center;
    }
    .product__card-text--color{
        color: var(--ksr-iron);
    }
    /* Wrappers */
    .product__card{
        width: 290px; 
        height: 464px;   
        transition: width 0.3s, height 0.3s;
    }
    .product__image{
        width: 290px;
        height: 164px;
        transition: width 0.3s, height 0.3s;
    }
    .product-card__button{
        width: 185px;
        height: 40px;
    }
    /* Styles */
    .product__Card--color{
        background-color: var(--ksr-smoke);
    }
    .product-card__button--style{
        color: var(--ksr-black);
        background-color: var(--ksr-yellow);
        transition: background-color .3s, color .3s;
    }
    .product-card__button--style:hover{
        color: var(--ksr-white);
        background-color: var(--ksr-gray);
        transition: background-color .3s, color .3s;
    }
    a{
        text-decoration: none;
    }
    .product-card__button--position{
        position: absolute;
        top: 400px;
        transition: top 0.3s;
    }
    @media (min-width: 992px) {
        .product__card{
            width: 484px;
            height: 484px;
            transition: width 0.3s, height 0.3s;
        }
        .product__image{
            width: 477px;
            height: 270px;
            transition: width 0.3s, height 0.3s;
        }
        .product-card__button--position{
            top: 415px;
            transition: top 0.3s;
        }
    }
</style>
